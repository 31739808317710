<template>
  <ul class="pagination">
    <li
      class="page-item pagination-prev-nav"
      :class="state.data.current_page === 1 ? 'disabled' : ''"
    >
      <a
        v-if="state.data.current_page === 1"
        aria-label="Previous"
        class="page-link"
        ><span aria-hidden="true">«</span
        ><span class="sr-only">Previous</span></a
      >
      <a
        v-else
        @click="changePagination(state.data.from)"
        aria-label="Previous"
        class="page-link"
        ><span aria-hidden="true">«</span
        ><span class="sr-only">Previous</span></a
      >
    </li>
    <li
      class="page-item pagination-page-nav"
      v-for="(page, index) in state.pageRange"
      :key="index"
      :class="page === state.data.current_page ? 'active' : ''"
    >
      <a href="#" class="page-link" @click="changePagination(page)"
        >{{ page }}
        <span class="sr-only" v-if="page === state.data.current_page"
          >(current)</span
        >
      </a>
    </li>
    <li
      class="page-item pagination-next-nav"
      :class="
        state.data.current_page === state.data.last_page ? 'disabled' : ''
      "
    >
      <a
        v-if="state.data.current_page === state.data.last_page"
        aria-label="Next"
        class="page-link"
        ><span aria-hidden="true">»</span><span class="sr-only">Next</span></a
      >
      <a
        v-else
        @click="changePagination(state.data.last_page)"
        aria-label="Next"
        class="page-link"
        ><span aria-hidden="true">»</span><span class="sr-only">Next</span></a
      >
    </li>
  </ul>
</template>
<script>
import { reactive, computed } from "vue";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    limit: {
        type: Number,
        default: 0
    },
  },
  setup(props) {
    const state = reactive({
      data: computed(() => {
        return props.data;
      }),
      pageRange: computed(() => {
        if (props.limit === -1) {
            return 0;
        }
        if (props.limit === 0) {
            return state.data.last_page;
        }
        var current = state.data.current_page;
        var last = state.data.last_page;
        var delta = props.limit;
        var left = current - delta;
        var right = current + delta + 1;
        var range = [];
        var pages = [];
        var l;
        for (var i = 1; i <= last; i++) {
          if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
          }
        }
        range.forEach(function (i) {
          if (l) {
            if (i - l === 2) {
              pages.push(l + 1);
            } else if (i - l !== 1) {
              pages.push("...");
            }
          }
          pages.push(i);
          l = i;
        });
        return pages;
      }),
    });
    return {
      state,
    };
  },
  methods: {
    changePagination(value) {
      this.$emit("paginate", value);
    },
  },
};
</script> 