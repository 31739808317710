<template>
  <!-- Sidebar -->
  <div
    id="sidebar-container"
    class="border-right sidebar-expanded sidebar-fixed d-none d-block"
  >
    <!-- Media top -->
    <div class="media d-none d-md-block">
      <div class="card-body px-0 px-sm-3 pb-2">
        <img
          src="../../../assets/img/avatar5.png"
          class="
            align-self-start
            float-left float-sm-none
            rounded-circle
            img-fluid
            mr-2
            w-25
          "
        />
        <div class="media-body mt-4 mt-sm-2">
          <p class="mb-0">{{ state.name }}</p>
          <p class="card-text mb-1 small">{{ state.role }}</p>
        </div>
      </div>
    </div>

    <button
      class="btn btn-outline-secondary ml-3 mt-3 d-md-none"
      data-toggle="collapse"
      data-target="#submenu"
    >
      Submenu
    </button>
    <ul
      id="submenu"
      class="
        list-group list-group-flush
        border-top border-bottom
        py-2
        collapse-sm collapse
      "
    >
      <li class="list-group-item border-0 p-0">
        <router-link to="/admin/pengguna" class="d-block bg-hover py-1 pl-3"
          >Pengguna</router-link
        >
      </li>
      <li class="list-group-item border-0 p-0">
        <router-link
          to="/admin/toko"
          class="d-block bg-hover py-1 pl-3"
          >Toko</router-link
        >
      </li>
      <li class="list-group-item border-0 p-0">
        <router-link
          to="/admin/reseller"
          class="d-block bg-hover py-1 pl-3"
          >Reseller</router-link
        >
      </li>
      <li class="list-group-item border-0 p-0">
        <router-link
          to="/admin/supplier"
          class="d-block bg-hover py-1 pl-3"
          >Supplier</router-link
        >
      </li>
      <li class="list-group-item border-0 p-0">
        <router-link
          to="/admin/pelanggan"
          class="d-block bg-hover py-1 pl-3"
          >Pelanggan</router-link
        >
      </li>
    </ul>
  </div>
  <!-- sidebar -->
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
export default {
  setup() {
    const state = reactive({
      name: 'Nama',
      role: 'User',
    })
    const loadData = async () => {
      await axios.get("/api/admin/me").then((res) => {
        if (res.data.success) {
          state.name = res.data.data.name;
          state.role = res.data.data.role.name;
        }
      });
    };
    onMounted(() => {
      loadData();
    })
    return {
      state,
    }
  },
}
</script>