<template>
<div class="container-fluid mt-header">
  <div id="body-sidemenu" class="row">
  <Sidebar />
  <!-- MAIN -->
  <div id="main-content" class="col with-fixed-sidebar">
    <div class="row">
      <nav aria-label="breadcrumb" class="w-100">
        <ol class="breadcrumb mb-0 rounded-0 bg-transparent">
          <li class="breadcrumb-item small">
            <router-link to="/admin/dasbord">Administrasi</router-link>
          </li>
          <li class="breadcrumb-item small active" aria-current="page">
            Toko
          </li>
        </ol>
      </nav>
    </div>

    <div class="h4 float-sm-left">Toko</div>
    <div class="float-sm-right mb-2 mb-md-0">
      <div class="d-md-block mr-3 mx-md-0" id="collapseExample">
        <a
          href="javascript:void(0);"
          class="btn btn-sm btn-outline-secondary mb-1 mb-md-0"
          title="Download tabel..."
          ><i class="fa fa-download mr-1"></i>Unduh</a
        >
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-12">
        <div class="card bg-light">
          <div class="card-body p-10">
            <form class="form-inline" action="javascript:void(0);">
              <label for="f1" class="mt-2 mt-sm-0 mr-sm-2">Filter:</label>
              <input
                type="text"
                class="form-control form-control-sm mr-sm-2"
                placeholder="Kata kunci pencarian..."
                id="f1"
              />
              <button
                type="submit"
                class="btn btn-sm btn-secondary mt-2 mt-md-0"
              >
                Filter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead class="thead-main border text-nowrap">
              <tr>
                <th>ID</th>
                <th>Nama Toko</th>
                <th>Nama Pengguna</th>
                <th>Email</th>
                <th>No HP</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody class="border text-nowrap" v-if="state.exist">
              <tr v-for="dt in state.data.data" :key="dt.id">
                <td>{{ dt.id }}</td>
                <td>{{ dt.nama }}</td>
                <td>{{ dt.user.name }}</td>
                <td>{{ dt.user.email }}</td>
                <td>{{ dt.user.phone }}</td>
                <td class="text-nowrap">
                  <a
                    href="#"
                    class="btn btn-sm color-main py-0"
                    title="View/Edit"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-sm color-main py-0"
                    title="Delete..."
                    ><i class="fa fa-trash"></i
                  ></a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center my-2">
                <td colspan="6">Data Tidak Tersedia!</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6">
                  <div class="d-flex justify-content-center my-2">
                    <Pagination
                        :data="state.data"
                        @paginate="updatePagination"
                        :limit="1"
                        v-if="state.finish"
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- main-content -->
</div>
</div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import Pagination from '../../../helpers/Pagination.vue';
import Sidebar from './Sidebar.vue';

export default {
  setup() {
    const state = reactive({
      data: {},
      finish: false,
      exist: false,
    });
    const loadData = async () => {
      await axios.get("/api/admin/toko").then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          state.finish = true;
          if(state.data.total > 0){
            state.exist = true;
          }else {
            state.exist = false;
          }
        }
      });
    };
    function updatePagination(dt) {
      state.finish = false;
      axios.get("/api/admin/toko?page=" + dt).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          state.finish = true;
          if(res.data.data.total > 0){
            state.exist = true;
          }else {
            state.exist = false;
          }
        }
      });
    }
    onMounted(() => {
      loadData();
    });
    return {
      state,
      updatePagination
    };
  },
  components: {
    Pagination,
    Sidebar,
  }
};
</script>